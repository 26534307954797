<template>
    <section class="section">
        <ValidationObserver v-slot="{ handleSubmit }">
            <v-form class="mt-15" @submit.prevent="handleSubmit(onSubmit)">
                <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="90vh">
                    <v-container>
                        <v-row class="mt-10">
                            <v-col cols="12" md="12">
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <ValidationProvider
                                            :name="$t('ShortName')"
                                            rules="required"
                                            v-slot="{ errors }">
                                            <v-text-field
                                                :error="errors[0] ? true : false"
                                                :disabled="isDisabled"
                                                :label="$t('ShortName')"
                                                required
                                                filled
                                                hide-details="auto"
                                                name="short_name"
                                                v-model="form.short_name"
                                                shaped></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <ValidationProvider :name="$t('LongName')" rules="required" v-slot="{ errors }">
                                            <v-text-field
                                                :error="errors[0] ? true : false"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                :label="$t('LongName')"
                                                name="long_name"
                                                hide-details="auto"
                                                v-model="form.long_name"
                                                required></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" md="4">
                                        <ValidationProvider :name="$t('Description')" v-slot="{ errors }">
                                            <v-text-field
                                                :error="errors[0] ? true : false"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                :label="$t('Description')"
                                                name="desc"
                                                v-model="form.desc"
                                                hide-details="auto"
                                                required></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <ValidationProvider name="Url" v-slot="{ errors }">
                                            <v-text-field
                                                :error="errors[0] ? true : false"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                label="Url"
                                                name="url"
                                                v-model="form.url"
                                                hide-details="auto"
                                                required></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" md="4">
                                        <ValidationProvider :name="$t('Color')" v-slot="{ errors }">
                                            <v-text-field
                                                :error="errors[0] ? true : false"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                :label="$t('Color')"
                                                name="color"
                                                hide-details="auto"
                                                class="route-color"
                                                v-model="form.color"
                                                required
                                                @focus="activateRouteColorPicker"
                                                @blur="deactivateRouteColorPicker"></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                        <v-color-picker
                                            dot-size="25"
                                            mode="hexa"
                                            swatches-max-height="200"
                                            hide-inputs
                                            v-model="form.color"
                                            ref="routeColorPicker"
                                            class="route-color-picker" />
                                    </v-col>

                                    <v-col cols="12" md="4">
                                        <ValidationProvider :name="$t('TextColor')" v-slot="{ errors }">
                                            <v-text-field
                                                :error="errors[0] ? true : false"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                :label="$t('TextColor')"
                                                name="textColor"
                                                hide-details="auto"
                                                v-model="form.text_color"
                                                required
                                                @focus="activateRouteTextColorPicker"
                                                @blur="deactivateRouteTextColorPicker"></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                        <v-color-picker
                                            dot-size="25"
                                            mode="hexa"
                                            swatches-max-height="200"
                                            hide-inputs
                                            v-model="form.text_color"
                                            ref="routeTextColorPicker"
                                            class="route-text-color-picker" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <ValidationProvider :name="$t('SortOrder')" v-slot="{ errors }">
                                            <v-text-field
                                                :error="errors[0] ? true : false"
                                                :disabled="isDisabled"
                                                filled
                                                shaped
                                                :label="$t('SortOrder')"
                                                name="sortOrder"
                                                v-model="form.sort_order"
                                                hide-details="auto"
                                                required></v-text-field>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <ValidationProvider :name="$t('ContinuosPickup')" v-slot="{ errors }">
                                            <v-select
                                                :error="errors[0] ? true : false"
                                                :disabled="isDisabled"
                                                :items="pickupType"
                                                filled
                                                shaped
                                                item-value="value"
                                                item-text="name"
                                                hide-details
                                                v-model="form.continuos_pickup"
                                                :label="$t('ContinuosPickup')"></v-select>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <ValidationProvider :name="$t('ContinuosDropOff')" v-slot="{ errors }">
                                            <v-select
                                                :error="errors[0] ? true : false"
                                                :disabled="isDisabled"
                                                :items="dropOffType"
                                                filled
                                                shaped
                                                item-value="value"
                                                item-text="name"
                                                v-model="form.continuos_drop_off"
                                                hide-details
                                                :label="$t('ContinuosDropOff')"></v-select>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" md="4">
                                        <ValidationProvider :name="$t('Type')" rules="required" v-slot="{ errors }">
                                            <v-select
                                                :error="errors[0] ? true : false"
                                                :disabled="isDisabled"
                                                :items="routeType"
                                                filled
                                                shaped
                                                item-value="value"
                                                item-text="name"
                                                v-model="form.type"
                                                :label="$t('Type')"></v-select>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <ValidationProvider :name="$t('Agency')" rules="required" v-slot="{ errors }">
                                            <v-autocomplete
                                                :error="errors[0] ? true : false"
                                                :disabled="isDisabled"
                                                v-model="form.agency"
                                                filled
                                                shaped
                                                :search-input.sync="agencySearch"
                                                @update:search-input="getAgencyName"
                                                :label="$t('Agency')"
                                                :items="agencys"
                                                item-text="name"
                                                item-value="id"
                                                auto-select-first
                                                clearable></v-autocomplete>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <ValidationProvider :name="$t('FareRules')" v-slot="{ errors }">
                                            <v-select
                                                :disabled="isDisabled"
                                                :items="fareRules"
                                                filled
                                                shaped
                                                item-value="id"
                                                item-text="id"
                                                v-model="form.mobility_fare_rules"
                                                :label="$t('FareRules')"></v-select>
                                            <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-sheet>
                <v-app-bar height="80" absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
                    <v-btn @click="goBack" class="square-button mx-3" color="grey-light">
                        <v-icon large color="grey">
                            {{ mdiChevronLeft }}
                        </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="!addRoute">
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('RouteProfile') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('RouteProfile') }}
                        </p>
                    </v-toolbar-title>
                    <v-toolbar-title v-else>
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('AddRoute') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('AddRoute') }}
                        </p>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <div v-if="!addRoute">
                        <v-btn type="submit" v-show="!isDisabled" :loading="isLoading" class="mx-3" color="primary">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                                {{ mdiCheck }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="ehite">
                                {{ mdiCheck }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Save') }}</span>
                        </v-btn>
                        <v-btn v-show="!isDisabled" @click="isDisabled = true" outlined class="mx-3" color="error">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Cancel') }}</span>
                        </v-btn>
                        <v-btn v-show="isDisabled" @click="editInformation" class="mx-3" color="grey-light">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Edit') }}</span>
                        </v-btn>

                        <v-dialog transition="dialog-top-transition" max-width="600">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-show="isDisabled" class="mx-3" color="grey-light" v-bind="attrs" v-on="on">
                                    <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                                <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>
                                <v-card>
                                    <v-card-text class="text-center">
                                        <p class="title font-weight-semibold black--text mt-12">
                                            {{ $t('Alerts.Sure') }}
                                        </p>
                                    </v-card-text>
                                    <v-card-text class="text-center">
                                        <p class="body-2">
                                            {{ $t('Alerts.SureDeleteRoute') }}
                                            <strong class="black--text">{{ form.long_name }}</strong> ?<br />
                                            {{ $t('Alerts.Irreversible') }}
                                        </p>
                                    </v-card-text>

                                    <v-card-actions class="justify-end mt-4">
                                        <v-btn @click="deleteRoute" :loading="isLoading" color="error" class="px-5">{{
                                            $t('Buttons.Delete')
                                        }}</v-btn>
                                        <v-btn text @click="dialog.value = false" :loading="isLoading">{{
                                            $t('Buttons.Cancel')
                                        }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </div>
                    <v-btn v-else type="submit" :loading="isLoading" class="mx-3" color="primary">
                        <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Conclude') }}</span>
                    </v-btn>
                </v-app-bar>
            </v-form>
        </ValidationObserver>
        <v-snackbar :timeout="2000" top v-model="createSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.AddRouteSueccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="updateSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.UpdateRouteSueccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="deleteSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.DeleteRouteSueccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isError" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Error') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.ErrorMessage') }}</p>
        </v-snackbar>
    </section>
</template>

<script>
    import {
        mdiChevronLeft,
        mdiFileDocumentEditOutline,
        mdiInformationOutline,
        mdiPlus,
        mdiTrashCanOutline,
        mdiCheck,
        mdiClose,
        mdiChevronRight,
        mdiMagnify,
    } from '@mdi/js';
    import _ from 'lodash';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { getAgencys } from '@/api/agency.js';
    import { getFareRules } from '@/api/fareRules.js';

    const initialState = () => ({
        isLoading: false,
        form: {
            agency: null,
            short_name: '',
            long_name: '',
            desc: '',
            type: null,
            url: '',
            color: '#FFFFFF',
            text_color: '#FFFFFF',
            sort_order: 0,
            continuos_pickup: null,
            continuous_drop_off: null,
            trips: [],
            attribution: '',
            favorites: [],
            mobility_fare_rules: null,
        },

        isLoadingTable: true,
    });

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
        },

        data() {
            return {
                ...initialState(),
                isDisabled: true,
                valid: false,

                addRoute: false,

                isError: false,
                createSuccess: false,
                updateSuccess: false,
                deleteSuccess: false,
                routeType: [
                    { name: 'Tram', value: 'O0' },
                    { name: 'Subway', value: 'O1' },
                    { name: 'Rail', value: 'O2' },
                    { name: 'Bus', value: 'O3' },
                    { name: 'Ferry', value: 'O4' },
                    { name: 'CableTram', value: 'O5' },
                    { name: 'AerialLift', value: 'O6' },
                    { name: 'Funicular', value: 'O7' },
                    { name: 'Trolleybus', value: 'O11' },
                    { name: 'Monorail', value: 'O12' },
                ],
                pickupType: [
                    { name: 'ContinuosPickup', value: 'O0' },
                    { name: 'NoContinuosPickup', value: 'O1' },
                    { name: 'PhoneAgencyPickup', value: 'O2' },
                    { name: 'PhoneDriverPickup', value: 'O3' },
                ],
                dropOffType: [
                    { name: 'ContinuosDropOff', value: 'O0' },
                    { name: 'NoContinuosDropOff', value: 'O1' },
                    { name: 'PhoneAgencyDropOff', value: 'O2' },
                    { name: 'PhoneDriverDropOff', value: 'O3' },
                ],
                agencys: [],
                debounce: null,
                agencySearch: '',
                fareRules: [],
            };
        },
        created() {
            this.getAgencyName();
            this.getFareRulesList();
            for (let index = 0; index < this.routeType.length; index++) {
                this.routeType[index].name = this.$t(this.routeType[index].name);
            }

            for (let index = 0; index < this.pickupType.length; index++) {
                this.pickupType[index].name = this.$t(this.pickupType[index].name);
            }

            for (let index = 0; index < this.dropOffType.length; index++) {
                this.dropOffType[index].name = this.$t(this.dropOffType[index].name);
            }

            if (this.$route.params.id != 'add') {
                this.addRoute = false;
                this.isDisabled = true;
                this.getRoute();
            } else {
                this.addRoute = true;
                this.isDisabled = false;
            }
        },

        methods: {
            editInformation() {
                this.isDisabled = !this.isDisabled;
            },

            getRoute() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-routes/fetchRoute', this.$route.params.id)
                    .then((response) => {
                        this.isLoading = false;

                        this.form = response.data.data;

                        if (!this.form.color) {
                            this.form.color = '#FFFFFF';
                        }

                        if (!this.form.text_color) {
                            this.form.text_color = '#FFFFFF';
                        }
                        this.form.agency = this.form.agency.id;
                        this.form.mobility_fare_rules = this.form.mobility_fare_rules[0].id;
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            this.form = {};
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

            activateRouteColorPicker() {
                this.$refs.routeColorPicker.$el.style.display = 'block';
            },

            deactivateRouteColorPicker() {
                this.$refs.routeColorPicker.$el.style.display = 'none';
            },

            activateRouteTextColorPicker() {
                this.$refs.routeTextColorPicker.$el.style.display = 'block';
            },

            deactivateRouteTextColorPicker() {
                this.$refs.routeTextColorPicker.$el.style.display = 'none';
            },

            async onSubmit() {
                if (this.addRoute) {
                    await this.onAddRoute();
                } else await this.onUpdateRoute();

                setTimeout(() => {
                    this.$router.push({ name: 'RoutesList', params: { tab: 0 } });
                }, 1500);
            },

            async onAddRoute() {
                this.isLoading = true;

                await this.$store
                    .dispatch('app-routes/addRoute', this.form)
                    .then(async (response) => {
                        this.createSuccess = true;
                    })
                    .catch((error) => {
                        this.isError = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.isDisabled = true;
                    });
            },

            async onUpdateRoute() {
                this.isLoading = true;

                await this.$store
                    .dispatch('app-routes/updateRoute', this.form)
                    .then(async (response) => {
                        this.updateSuccess = true;
                    })
                    .catch((error) => {
                        this.isError = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.isDisabled = true;
                    });
            },

            deleteRoute() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-routes/deleteRoute', this.$route.params.id)
                    .then((response) => {
                        this.deleteSuccess = true;
                        setTimeout(() => {
                            this.$router.push({ name: 'RoutesList', params: { tab: 0 } });
                        }, 1500);
                    })
                    .catch((error) => {
                        this.isError = true;
                    });
            },

            getAgencyName() {
                clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                    let filter = {
                        name: {
                            $containsi: this.agencySearch,
                        },
                    };
                    getAgencys({ filters: filter })
                        .then((response) => {
                            this.agencys = response.data.data;
                        })
                        .catch((error) => {
                            throw error;
                        });
                }, 500);
            },

            getFareRulesList() {
                getFareRules()
                    .then((response) => {
                        this.fareRules = response.data.data;
                    })
                    .catch((error) => {
                        throw error;
                    });
            },

            goBack() {
                this.$router.push({ name: 'RoutesList', params: { tab: 0 } });
            },
        },

        computed: {
            color: {
                get() {
                    return this.form.color;
                },
                set(value) {
                    this.form.color = value;
                },
            },

            text_color: {
                get() {
                    return this.form.text_color;
                },
                set(value) {
                    this.form.text_color = value;
                },
            },
        },

        setup() {
            return {
                mdiInformationOutline,
                mdiFileDocumentEditOutline,
                mdiCheck,
                mdiClose,
                mdiTrashCanOutline,
                mdiPlus,
                mdiChevronLeft,
                mdiChevronRight,
                mdiMagnify,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
        box-shadow: 0 0px 0px 0px rgb(94 86 105 / 42%) !important;
    }

    .route-color-picker {
        position: absolute;
        z-index: 10;
        display: none;
    }

    .route-text-color-picker {
        position: absolute;
        z-index: 10;
        display: none;
    }
</style>
